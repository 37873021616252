/**
 * NOTE: This JS is included on every page of avvoka, please be aware of what you put here.
 */

import {createPinia, setActivePinia} from "pinia";
import * as Vue from "vue"
import * as Vuex from "vuex"

setActivePinia(createPinia())

import "../channels/consumer"

window.Vue = Vue;
window.Vuex = Vuex;
